<script setup lang="ts">
import { map } from 'rxjs/operators'
import { PersonalApi } from '~/net/apis/personal_api'

const onClickLeft = () => history.back()
const data = useObservable(from(PersonalApi.customerAddr()).pipe(map(({ data }) => data)))
</script>

<template>
  <div h-screen>
    <van-nav-bar title="客服" left-arrow @click-left="onClickLeft" />
    <iframe v-if="data" :src="data" w-full flex-1 class="iframe" />
  </div>
</template>

<style scoped>
:deep(.van-nav-bar__content) {
  background: #111425;
}
:deep(.van-nav-bar__title) {
  color: #d0d0d0;
}
:deep(.van-nav-bar .van-icon) {
  color: #ffffff;
}
:deep(.van-hairline--bottom:after) {
  border-bottom-width: 0;
}
.iframe{
  height: calc(100vh - 46px);
}
</style>
